<template>
  <div>
    <div v-if="model===false">
      <div class="screen">
        <div style="height:75%;display: flex;justify-content: space-between;padding:3.023609394314vh 8.09763142692085vw 3.023609394314vh 8.09763142692085vw ;">
          <div class="left">
            <div class="left3" style="width: fit-content">
              <span style="cursor: pointer" @click="gptsProfessional()">生态平台</span><br>
              <div @click="gptsMove1()" class="community">安同油气商城</div>
              <div @click="gptsMove2()" class="community">油气岛  </div>
              <div @click="gptsMove3()" class="community">油气社区    </div>
              <div @click="gptsMove4()" class="community">油气通    </div>
              <div @click="gptsMove5()" class="community"> 油气知识    </div>
              <div @click="gptsMove6()" class="community"> 油气人才  </div>
            </div>
            <div class="left3" style="margin-left:4vw">
              <span style="cursor: pointer" @click="gptsProfessional()">地质工程云平台GPTs</span><br>
              <div @click="gptsGround1()" class="community">GEGPTs</div>
              <div @click="gptsGround3()" class="community">全球在线专家支持服务  </div>
              <div @click="gptsGround2()" class="community">油气田开发系列技术服务    </div>
<!--              <div @click="gptsGround4()" class="community">增产整体解决方案GPTs    </div>-->
<!--              <div @click="gptsGround5()" class="community"> 油气田开发动态监测GPTs    </div>-->
            </div>
            <div class="left1">
              <span style="cursor: pointer">服务反馈</span><br>
              <div class="community">作业项目服务评价反馈</div>
              <div class="community">产品评价反馈</div>
              <div class="community">网页使用体验反馈 </div>
<!--              <span style="cursor: pointer" @click="gpt3">关于我们</span><br>-->
<!--              <div @click="gpt3()" class="community">研究院介绍</div>-->
<!--              <div @click="gpt3()" class="community">主营业务</div>-->
<!--              <div @click="gpt3()" class="community">组织机构 </div>-->
<!--              <div @click="gpt3()" class="community">发展历程  </div>-->
<!--              <div @click="gpt3()" class="community">团队建设</div>-->
<!--              <div @click="gpt3()" class="community">人才招聘</div>-->
            </div>
            <div class="left2">
              <span @click="dialogVisible = true" style="cursor:pointer;color:#686eff;border-bottom: #686eff 1px solid">产品及合作咨询</span><br>
<!--              <div class="community">技术咨询：<span @click="dialogVisible = true">产品咨询</span></div>-->
              <div class="community">联系我们：<span>+86-13880250028</span></div>
            </div>
          </div>
          <div class="right">
<!--            <div class="left2">-->
<!--              <div class="up">-->
<!--                <img loading="lazy" src="@/assets/erweima.png">-->
<!--              </div>-->
<!--              <div class="down">扫一扫 进入小程序</div>-->
<!--            </div>-->

            <div class="left2" @click="mobile1">
              <div class="up">
                <img loading="lazy" src="@/assets/ground.png">
              </div>
              <div class="down">扫一扫 进入移动端</div>
            </div>
            <div class="left2" @click="mobile2">
              <div class="up">
                <img loading="lazy" src="@/assets/groundAntonOffice.png">
              </div>
              <div class="down">扫一扫进入安东官网</div>
            </div>
            <div class="left2" @click="mobile3">
              <div class="up">
                <img loading="lazy" src="@/assets/groundAnton.png">
              </div>
              <div class="down">扫一扫进入安东在线</div>
            </div>
          </div>
        </div>
        <div class="foot">
          <div class="flex">
            <p>北京市怀柔区雁栖镇京加路青秀园街5号院</p>
            <span> | </span>
            <p>安东地质工程技术研究院（北京）有限公司版权所有</p>
            <span> | </span>
            <p class="red">
              <a href="https://beian.miit.gov.cn/">京ICP备2024077635号-1</a>
            </p>
          </div>
          <div
              style="
          width: fit-content;
          font-size: 12px;
          margin: 0 auto;
          padding: 5px 0px 10px;
        "
          >
            <a
                target="_blank"
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502053484"
                style="display: inline-block; text-decoration: none"
            >
              <img loading="lazy" src="@/assets/beian.png" style="float: left; width: 17px" />
              <p
                  style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
              >
                京ICP备2024077635号
              </p></a
            >
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="screenModel" ref="screenModel">
        <div class="foot">
          <div class="left1">
            <span @click="gptsProfessional()" style="color: white;font-size: 14px">地质工程云平台GPTs</span>
            <span @click="hidden3" v-if="hid2===0" style="float: right;color: white"><i class="el-icon-caret-top"></i> </span>
            <span @click="hidden23" v-if="hid2===1" style="float: right;color: white"><i class="el-icon-caret-bottom"></i> </span>
            <br>
            <div v-if="hid2===0" @click="gptsGround1()" class="community">油藏地质精细研究GPTs</div>
            <div v-if="hid2===0" @click="gptsGround2()" class="community">油气增产技术GPTs</div>
            <div v-if="hid2===0" @click="gptsGround3()"  class="community">工程地质技术GPTs</div>
            <div v-if="hid2===0" @click="gptsGround4()"  class="community">增产整体解决方案GPTs</div>
            <div v-if="hid2===0" @click="gptsGround5()"  class="community"> 油气田开发动态监测GPTs</div>
          </div>

            <div class="left1" >
              <span @click="gpt3()" style="color: white;font-size: 14px">关于我们</span>
              <span @click="hidden" v-if="hid===0" style="float: right;color: white"><i class="el-icon-caret-top"></i> </span>
              <span @click="hidden2" v-if="hid===1" style="float: right;color: white"><i class="el-icon-caret-bottom"></i> </span>
              <br>
              <div v-if="hid===0" @click="gpt3()" class="community">研究院介绍</div>
              <div v-if="hid===0" @click="gpt3()" class="community">主营业务</div>
              <div v-if="hid===0" @click="gpt3()" class="community">组织机构</div>
              <div v-if="hid===0" @click="gpt3()" class="community">发展历程</div>
              <div v-if="hid===0" @click="gpt3()" class="community">团队建设</div>
              <div v-if="hid===0" @click="gpt3()" class="community">人才招聘</div>
            </div>
            <div class="left2">
              <span style="color: white;font-size: 14px">技术推广</span>
              <span @click="hidden1" v-if="hid1===0" style="float: right;color: white"><i class="el-icon-caret-top"></i> </span>
              <span @click="hidden12" v-if="hid1===1" style="float: right;color: white"><i class="el-icon-caret-bottom"></i> </span>
              <br>
              <div v-if="hid1===0" class="community">技术咨询:<span @click="dialogVisible = true">产品咨询</span></div>
              <div v-if="hid1===0" class="community">联系我们:<span>+86-13880250028</span></div>
            </div>
          <div class="left3">
          </div>
          <div class="flex">
            <p>北京市怀柔区雁栖镇京加路青秀园街5号院</p>
          </div>
          <div class="flex">
            <p>版权所有 © 安东地质工程技术研究院（北京）有限公司</p>
          </div>
          <div
              style="
          width: fit-content;
          font-size: 12px;
          margin: 0 auto;
          padding: 5px 0px 10px;
        "
          >
            <a
                target="_blank"
                style="display: inline-block; text-decoration: none"
            >
              <p
                  style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
              >
                京ICP备2024077635号
              </p></a
            >
          </div>
        </div>
      </div>
    </div>
    <consultTip
        :dialogVisible="dialogVisible"
        @hideDialog="hideDialog"
    ></consultTip>
  </div>

</template>


<script>

import consultTip from "@/components/consultTip.vue";

export default {

  name: "",

  props: [],

  components: {consultTip},

  data() {

    return {
      hid:0,
      hid1:0,
      hid2:0,
      dialogVisible: false,
      model:false,
    }

  },
  beforeRouteLeave(to, from, next) {
    // 保存滚动位置
    this.scrollPosition = this.$refs.screenModel.scrollTop;
    next();
  },
  //进入该页面时，用之前保存的滚动位置赋值
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // 恢复滚动位置
      vm.$nextTick(() => {

        vm.$refs.screenModel.scrollTop = vm.scrollPosition;

      });
    });
  },
  methods: {
    mobile1(){
      window.open("https://cn.ogdri.com/")
    },
    mobile2(){
      window.open("https://cn.antonoil.com/")
    },
    mobile3(){
      window.open("https://cnmall.antonoil.com/")
    },
    hidden(){
      this.hid=1
    },
    hidden2(){
      this.hid=0
    },
    hidden1(){
      this.hid1=1
    },
    hidden12(){
      this.hid1=0
    },
    hidden3(){
      this.hid2=1
    },
    hidden23(){
      this.hid2=0
    },
    gpts1(){
      window.open("https://www.oilgascommunity.com/sortList?id=40", "_blank");
    },
    gpts2(){
      window.open("https://www.oilgascommunity.com/sortList?id=41", "_blank");
    },
    gpt3(){
      window.open("/about", "_blank");
    },
    gptsProfessional(){

      console.log(localStorage.getItem("home"))
      if (localStorage.getItem("home")==="1") {
        this.$emit("showDialogBottom",0);
      } else {
        sessionStorage.setItem("ground", 0)
        window.open("/", "_blank");
      }
    },
    gptsMove1(){
      window.open("https://cn.atoilgas.com/")
    },
    gptsMove2(){
      window.open("https://cn.oilgasdao.com/")
    },
    gptsMove3(){
      window.open("https://www.oilgascommunity.com/")
    },
    gptsMove4(){
      window.open("https://cn.oilgasgpts.com/")
    },
    gptsMove5(){
      window.open("https://cn.oilgasinfoai.com/")
    },
    gptsMove6(){
      window.open("https://www.oilgasjobai.com/")
    },
    gptsGround1(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",1);
      } else {
        sessionStorage.setItem("ground", 1)
        window.open("/", "_blank");
      }

    },
    gptsGround2(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",2);
      } else {
        sessionStorage.setItem("ground", 2)
        window.open("/", "_blank");
      }

    },
    gptsGround3(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",3);
      } else {
        sessionStorage.setItem("ground", 3)
        window.open("/", "_blank");
      }

    },
    gptsGround4(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",4);
      } else {
        sessionStorage.setItem("ground", 4)
        window.open("/", "_blank");
      }
    },
    gptsGround5(){
      const routeName = this.$route.name;
      console.log(routeName)
      if (routeName==="Home") {
        this.$emit("showDialogBottom",6);
      } else {
        sessionStorage.setItem("ground", 6)
        window.open("/", "_blank");
      }
    },
    gptsProfessional1(){
      sessionStorage.setItem("skip", 5)
      if (localStorage.getItem("home")==="1") {
        window.location.reload()
      } else {
        this.$router.push("/");
      }



    },
    gptsDesign1(){
      sessionStorage.setItem("skip", 3)
      this.$router.push("/gptOil");
    },
    gptsTranslate1(){
      sessionStorage.setItem("skip", 3)
      this.$router.push("/gptTranslate");
    },
    gptsTechnology1(){
      sessionStorage.setItem("skip", 3)
      this.$router.push("/gptHole",);
    },
    gpts11(){
      sessionStorage.setItem("skip", 0)
      const externalLink = 'https://www.oilgascommunity.com/recently?id=40';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    gpts21(){
      sessionStorage.setItem("skip", 3)
      const externalLink = 'https://www.oilgascommunity.com/recently?id=41';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});
    },
    gpts13(){
      sessionStorage.setItem("skip", 3)
      const externalLink = 'https://www.oilgascommunity.com/recently?id=39';
      // 导航到外部链接处理路由，并传递外部链接作为参数
      this.$router.push({name: 'ExternalLinkHandler', params: {url: externalLink}});

    },
    hideDialog() {
      this.dialogVisible = false;
    },

  },

  mounted() {

      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

// Log the values1731 809   1653 834   1912 932
      console.log("Screen Width: ", screenWidth);
      console.log("Screen Height: ", screenHeight);

      if(screenWidth<=700){
        this.model=true
      }else{
        this.model=false
      }


  }

}

</script>


<style type="text/scss" lang="scss" scoped>
.screen{
  z-index: 9999999999999999999;
  width: 100%;
  height: fit-content;
  background-color: #333333;
  .foot {
    padding-top: 15px;
    text-align: left;
    border-top: 1px solid #373b4f;
    .flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      text-align: center;
      width: fit-content;
      color: #999999;
      margin: 0;
    }

    span {
      padding: 0 10px;
      color: #999999;
      font-size: 12px;
    }

    p:nth-child(1),
    p:nth-child(3) {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
    }

    p.red {
      color: #ef3e4f;
      /* font-weight: bold; */
      font-size: 12px;
      line-height: 30px;
    }

    p.red a {
      color: #999999;
    }
  }
  .yuming{
    color:white;

  }

  .left{
    padding-top: 3vh;
    display: flex;
    color:#FFFFFF;
    .left1{
      margin-left:2vw;
      width: fit-content;
      font-size: 0.8709416522vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FFFFFF;
      line-height: 4.33745364647713vh;
      .community{
        color:gray;
        cursor: pointer;
        font-size: 0.7709416522vw
      }
      .community:hover{
        color:white;
        font-size: 0.7709416522vw
      }
    }
    .left3{
      //margin-left: 0.6932409012vw;
      width: fit-content;
      font-size: 0.8709416522vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FFFFFF;
      line-height: 4.33745364647713vh;
      .community{
        color:gray;
        cursor: pointer;
        font-size: 0.7709416522vw
      }
      .community:hover{
        color:white;
        font-size: 0.7709416522vw
      }
    }
    .left2{
      margin-left:2vw;
      width: 25.51415366839977vw;
      font-size: 0.8709416522vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FFFFFF;
      line-height: 4.33745364647713vh;
      .community{
        color:gray;
        cursor: pointer;
        font-size: 0.7709416522vw
      }
      .community:hover{
        color:white;
        font-size: 0.7709416522vw
      }
    }

  }
  .right{
    margin-top: 2vh;
    display: flex;
    color:#FFFFFF;
    .left2{
      cursor: pointer;
      margin-left:2vw;
      width: 7vw;
      font-size: 0.7709416522vw;
      font-family: Sarial, sans-serif, "Microsoft Yahei";
      font-weight: 400;
      color: #FFFFFF;
      text-align: center;
      line-height: 3.33745364647713vh;
      .up{
        margin:0 auto;
        width: 7vw;
        height: auto;
        //background-image:url("../assets/attention.png");
        background-size:cover;
        background-position:center;
        background-repeat:no-repeat
      }
      .down{
        width:  7vw;
        margin:0 auto;
        text-align: center;
      }
    }

  }
}
.screenModel{
  width: 100%;
  height: fit-content;
  background-color: #333333;
  .left1{
    width: 90%;
    margin:0 15px;
    font-size: 14px;
    color: white;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    .community{
      margin-left: 12px;
      color:gray;
      cursor: pointer;
      font-size:12px
    }

  }
  .left2{
    width: 90%;
    margin:0 15px;
    color: white;
    text-align: left;
    font-size: 14px;
    font-family: Sarial, sans-serif, "Microsoft Yahei";
    font-weight: 400;
    line-height: 32px;
    .community{
      margin-left: 12px;
      color:gray;
      cursor: pointer;
      font-size: 12px
    }
  }
  .left3{
  width: 100%;
    margin:15px 0;
    height: 1px;
    background-color: #484848;
  }

  .foot {
    padding-top: 15px;
    text-align: center;
    border-top: 1px solid #373b4f;
    .flex {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      text-align: center;
      width: fit-content;
      color: #999999;
      margin: 0;
    }

    span {
      padding: 0 10px;
      color: #999999;
      font-size: 12px;
    }

    p:nth-child(1),
    p:nth-child(3) {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      line-height: 30px;
    }

    p.red {
      color: #ef3e4f;
      /* font-weight: bold; */
      font-size: 12px;
      line-height: 30px;
    }

    p.red a {
      color: #999999;
    }
  }

}
</style>
